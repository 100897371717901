import api from "./api";

export const getAllAccounts = async (params: { page: number; size: number; query?: string }) => {
  const { page, size, query } = params;
  const response = await api.get(`/users/`, {
    params: { page, size, query },
  });
  return response.data;
};


export const createAccount = async (accountData: {
  phone: string;
  fullname: string;
}) => {
  const response = await api.post("/auth/register-ship", accountData);
  return response.data;
};

