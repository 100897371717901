import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProductById } from "../services/product";

interface ProductDetail {
  id: string;
  name: string;
  images: { link: string }[];
  type: { id: string; name: string }[];
  shop: { id: string; name: string };
  price: number;
  description: string;
  created_time: string;
  is_active: boolean;
}

const ProductDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<ProductDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const data = await getProductById(id!);
        setProduct(data);
      } catch (err: any) {
        setError(err.response?.data?.message || "Lỗi khi tải dữ liệu.");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) return <p>Đang tải dữ liệu...</p>;
  if (error) return <p>{error}</p>;

  if (!product) return <p>Không tìm thấy sản phẩm.</p>;

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Chi tiết sản phẩm</h1>
      <p>
        <strong>Tên:</strong> {product.name}
      </p>
      <p>
        <strong>Giá:</strong> {product.price.toLocaleString()} VND
      </p>
      <p>
        <strong>Shop:</strong> {product.shop.name}
      </p>
      <p>
        <strong>Mô tả:</strong> {product.description}
      </p>
      <p>
        <strong>Trạng thái:</strong> {product.is_active ? "Hoạt động" : "Ngừng"}
      </p>
      <p>
        <strong>Loại:</strong> {product.type.map((t) => t.name).join(", ")}
      </p>
      <p>
        <strong>Thời gian tạo:</strong> {new Date(product.created_time).toLocaleString()}
      </p>
      <div className="mt-4">
        <img
          src={product.images[0]?.link || ""}
          alt={product.name}
          className="w-64 h-64 object-cover"
        />
      </div>
    </div>
  );
};

export default ProductDetail;
