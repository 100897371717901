import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUploadLink, uploadImage } from "../services/product";
import { createType } from "../services/types";
import Sidebar from "../components/Siderbar";

interface OptionType {
    value: string;
    label: string;
}

const TypeAdd: React.FC = () => {
    const navigate = useNavigate();

    const [name, setName] = useState<string>("");
    const [image, setImage] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleImageUpload = async (): Promise<string | null> => {
        if (!image) return null;

        try {
            const uploadLinkResponse = await getUploadLink(image.name, image.type);
            const { upload, object } = uploadLinkResponse;

            const uploadSuccess = await uploadImage(image, upload);
            if (uploadSuccess) {
                return object;
            }
        } catch (error) {
            console.error("Image upload failed:", error);
        }

        return null;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const uploadedImageObject = await handleImageUpload();
            if (!uploadedImageObject) {
                alert("Hình ảnh không thể tải lên");
                return;
            }

            const typeData = {
                name,
                images: uploadedImageObject,
            };

            await createType(typeData);
            alert("Thêm thành công");
            navigate("/types");
        } catch (error) {
            console.error("Error creating product:", error);
            alert("Đã xảy ra lỗi khi thêm sản phẩm");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex h-screen">
            <Sidebar />
            <div className="p-6">
                <h1 className="text-2xl font-bold mb-4">Thêm Types</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Tên</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Hình ảnh</label>
                        <input
                            type="file"
                            onChange={(e) => setImage(e.target.files?.[0] || null)}
                            className="w-full px-4 py-2 border rounded-lg"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className={`px-4 py-2 text-white rounded-lg ${isSubmitting ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
                            }`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Đang thêm..." : "Thêm sản phẩm"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default TypeAdd;
