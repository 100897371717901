import React, { useState } from "react";
import Select from "react-select";
import axios from "axios";

interface OptionType {
  value: string;
  label: string;
}

interface PlaceSearchProps {
  onPlaceSelect: (place: { address: string; latitude: string; longitude: string }) => void;
}

const PlaceSearch: React.FC<PlaceSearchProps> = ({ onPlaceSelect }) => {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [selectedPlace, setSelectedPlace] = useState<OptionType | null>(null);

  const handleInputChange = async (inputValue: string) => {
    if (inputValue.trim().length < 3) return; // Không tìm kiếm nếu ít hơn 3 ký tự

    try {
      const response = await axios.get("https://rsapi.goong.io/place/autocomplete", {
        params: {
          api_key: "cyqYoiHZ8OhH1hz3RnEb43cNpgNHB9o7nH0GebFq", // Thay bằng API Key từ Goong.io
          input: inputValue,
        },
      });

      const predictions = response.data.predictions.map((pred: any) => ({
        value: String(pred.place_id), // Đảm bảo là chuỗi
        label: String(pred.description), // Đảm bảo là chuỗi
      }));

      setOptions(predictions); // Cập nhật `options`
    } catch (error) {
      console.error("Error fetching autocomplete data:", error);
    }
  };

  const handleSelectChange = async (selected: OptionType | null) => {
    setSelectedPlace(selected);
    if (!selected) return;

    try {
      const response = await axios.get("https://rsapi.goong.io/place/detail", {
        params: {
          api_key: "cyqYoiHZ8OhH1hz3RnEb43cNpgNHB9o7nH0GebFq", // Thay bằng API Key của bạn
          place_id: selected.value,
        },
      });
      const result = response.data.result;
      onPlaceSelect({
        address: String(result.formatted_address), // Đảm bảo là chuỗi
        latitude: String(result.geometry.location.lat), // Đảm bảo là chuỗi
        longitude: String(result.geometry.location.lng), // Đảm bảo là chuỗi
      });
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  return (
    <Select
      value={selectedPlace}
      onChange={handleSelectChange}
      onInputChange={(value) => {
        handleInputChange(value); // Gọi hàm xử lý tìm kiếm
        return value; // Đảm bảo trả về chuỗi cho react-select
      }}
      options={options}
      placeholder="Tìm kiếm địa điểm..."
      className="w-full"
      filterOption={null} // Tắt bộ lọc mặc định
    />

  );
};

export default PlaceSearch;
