import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createProduct, getTypes, getShops, getUploadLink, uploadImage } from "../services/product";
import Select from "react-select";

interface OptionType {
  value: string;
  label: string;
}

const ProductAdd: React.FC = () => {
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [typesSelected, setTypesSelected] = useState<OptionType[]>([]); // Chọn nhiều loại
  const [shopSelected, setShopSelected] = useState<OptionType | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [typesOptions, setTypesOptions] = useState<OptionType[]>([]);
  const [shopsOptions, setShopsOptions] = useState<OptionType[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      const typesData = await getTypes();
      const shopsData = await getShops();

      setTypesOptions(typesData.map((type: any) => ({ value: type.id, label: type.name })));
      setShopsOptions(shopsData.map((shop: any) => ({ value: shop.id, label: shop.name })));
    };

    fetchInitialData();
  }, []);

  const handleImageUpload = async (): Promise<string | null> => {
    if (!image) return null;

    try {
      const uploadLinkResponse = await getUploadLink(image.name, image.type);
      const { upload, object } = uploadLinkResponse;

      const uploadSuccess = await uploadImage(image, upload);
      if (uploadSuccess) {
        return object;
      }
    } catch (error) {
      console.error("Image upload failed:", error);
    }

    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const uploadedImageObject = await handleImageUpload();
      if (!uploadedImageObject) {
        alert("Hình ảnh không thể tải lên");
        return;
      }

      const productData = {
        name,
        images: [uploadedImageObject],
        type: typesSelected.map((type) => type.value), // Gửi mảng các loại sản phẩm
        price,
        description,
        shop: shopSelected?.value || "",
      };

      await createProduct(productData);
      alert("Thêm sản phẩm thành công");
      navigate("/products");
    } catch (error) {
      console.error("Error creating product:", error);
      alert("Đã xảy ra lỗi khi thêm sản phẩm");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Thêm Sản Phẩm</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Tên sản phẩm</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Giá</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Mô tả</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Loại sản phẩm</label>
          <Select
            isMulti
            options={typesOptions}
            value={typesSelected}
            onChange={(selected) => setTypesSelected(selected as OptionType[])}
            className="w-full"
            placeholder="Chọn loại sản phẩm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Shop</label>
          <Select
            options={shopsOptions}
            value={shopSelected}
            onChange={(selected) => setShopSelected(selected as OptionType)}
            className="w-full"
            placeholder="Chọn shop"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Hình ảnh</label>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files?.[0] || null)}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
        </div>
        <button
          type="submit"
          className={`px-4 py-2 text-white rounded-lg ${
            isSubmitting ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
          }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Đang thêm..." : "Thêm sản phẩm"}
        </button>
      </form>
    </div>
  );
};

export default ProductAdd;
