import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createAccount } from "../services/account";
import Sidebar from "../components/Siderbar";


const AccountAdd: React.FC = () => {
  const navigate = useNavigate();

  const [fullname, setFullname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const accountData = {
        fullname,
        phone
      };
      await createAccount(accountData);
      alert("Thêm account thành công");
      navigate("/accounts");
    } catch (error) {
      console.error("Error creating product:", error);
      alert("Đã xảy ra lỗi khi thêm");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-4">Thêm Account</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Tên</label>
            <input
              type="text"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Phone</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone((e.target.value))}
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <button
            type="submit"
            className={`px-4 py-2 text-white rounded-lg ${isSubmitting ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
              }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Đang thêm..." : "Thêm acc"}
          </button>
        </form>
      </div>
    </div>

  );
};

export default AccountAdd;
