import React, { useEffect, useState } from "react";
import { deleteProducts, getAllProducts } from "../services/product";
import { Link } from "react-router-dom";

interface Product {
    id: string;
    name: string;
    images: { link: string }[];
    type: { id: string; name: string }[];
    shop: { id: string; name: string };
    price: number;
    description: string;
    is_active: boolean;
    liked: number;
}

const Products: React.FC = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [query, setQuery] = useState<string>(""); // Tìm kiếm
    const [page, setPage] = useState<number>(0); // Trang hiện tại
    const [size, setSize] = useState<number>(10); // Số sản phẩm mỗi trang
    const [totalPage, setTotalPage] = useState<number>(1); // Tổng số trang

    const fetchProducts = async () => {
        try {
            setLoading(true);
            const data = await getAllProducts({ query, size, page });
            setProducts(data.data);
            setTotalPage(data.total_page);
        } catch (err: any) {
            setError(err.response?.data?.message || "Lỗi khi tải dữ liệu.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [query, page, size]); // Gọi lại API khi query, page, hoặc size thay đổi

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        setPage(0); // Reset về trang đầu tiên khi tìm kiếm
        fetchProducts();
    };

    const handlePageClick = (newPage: number) => {
        setPage(newPage);
    };


    const handleSelectProduct = (id: string) => {
        if (selectedProducts.includes(id)) {
            setSelectedProducts(selectedProducts.filter((productId) => productId !== id));
        } else {
            setSelectedProducts([...selectedProducts, id]);
        }
    };

    const handleSelectAll = () => {
        if (selectedProducts.length === products.length) {
            setSelectedProducts([]);
        } else {
            setSelectedProducts(products.map((product) => product.id));
        }
    };

    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const handleDeleteSelected = async () => {
        if (selectedProducts.length === 0) {
            alert("Vui lòng chọn ít nhất một sản phẩm để xóa.");
            return;
        }

        const confirmDelete = window.confirm("Bạn có chắc chắn muốn xóa các sản phẩm đã chọn?");
        if (!confirmDelete) return;

        try {
            await deleteProducts(selectedProducts);
            alert("Xóa sản phẩm thành công.");
            setSelectedProducts([]);
            fetchProducts();
        } catch (err) {
            console.error("Error deleting products:", err);
            alert("Đã xảy ra lỗi khi xóa sản phẩm.");
        }
    };


    if (loading) return <p>Đang tải dữ liệu...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Quản lý Products</h1>
            {/* Tìm kiếm */}

            <Link
                to="/products/add"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
                Thêm sản phẩm
            </Link>
            <form onSubmit={handleSearch} className="mb-4 flex">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Tìm kiếm sản phẩm..."
                    className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                />
                <button
                    type="submit"
                    className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                    Tìm kiếm
                </button>
            </form>
            <div className="mb-4">
                <button
                    onClick={handleDeleteSelected}
                    className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                    disabled={selectedProducts.length === 0}
                >
                    Xóa đã chọn
                </button>
            </div>
            {/* Bảng danh sách sản phẩm */}
            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border px-4 py-2">
                            <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                checked={selectedProducts.length === products.length && products.length > 0}
                            />
                        </th>
                        <th className="border px-4 py-2 text-left">Tên</th>
                        <th className="border px-4 py-2 text-left">Hình ảnh</th>
                        <th className="border px-4 py-2 text-left">Loại</th>
                        <th className="border px-4 py-2 text-left">Shop</th>
                        <th className="border px-4 py-2 text-left">Giá</th>
                        <th className="border px-4 py-2 text-left">Mô tả</th>
                        <th className="border px-4 py-2 text-left">Trạng thái</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => (
                        <tr key={product.id}>
                            <td className="border px-4 py-2">
                                <input
                                    type="checkbox"
                                    checked={selectedProducts.includes(product.id)}
                                    onChange={() => handleSelectProduct(product.id)}
                                />
                            </td>
                            <td className="border px-4 py-2">{product.name}</td>
                            <td className="border px-4 py-2">
                                <img
                                    src={product.images[0]?.link || ""}
                                    alt={product.name}
                                    className="w-20 h-20 object-cover"
                                />
                            </td>
                            <td className="border px-4 py-2">
                                {product.type.map((t) => t.name).join(", ")}
                            </td>
                            <td className="border px-4 py-2">{product.shop.name}</td>
                            <td className="border px-4 py-2">{product.price.toLocaleString()} VND</td>
                            <td className="border px-4 py-2">{product.description}</td>
                            <td className="border px-4 py-2">
                                {product.is_active ? "Hoạt động" : "Ngừng"}
                            </td>
                            <td className="border px-4 py-2">
                                <Link
                                    to={`/products/edit/${product.id}`}
                                    className="text-green-500 hover:underline mr-4"
                                >
                                    Chỉnh sửa
                                </Link>
                            </td>
                            <td className="border px-4 py-2">
                                <Link
                                    to={`/products/${product.id}`}
                                    className="text-blue-500 hover:underline"
                                >
                                    Xem chi tiết
                                </Link>
                            </td>
                        </tr>

                    ))}
                </tbody>
            </table>
            {/* Thanh phân trang */}
            <div className="flex justify-center items-center mt-4 space-x-2">
                {Array.from({ length: totalPage }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageClick(index)}
                        className={`px-4 py-2 border rounded-lg ${page === index
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 hover:bg-gray-300"
                            }`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Products;
