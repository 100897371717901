import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteShops, getAllShops } from "../services/shop";

interface Shop {
    id: string;
    name: string;
    address: string;
    phone: string;
    latitude: string;
    longitude: string;
    is_active: boolean;
    time_open: string;
    time_close: string;
    images?: {
        object: string;
        link: string;
    };
}

const Shops: React.FC = () => {
    const [shops, setShops] = useState<Shop[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [query, setQuery] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [totalPage, setTotalPage] = useState<number>(1);

    const fetchShops = async () => {
        try {
            setLoading(true);
            const data = await getAllShops({ page, size, query });
            setShops(data.data);
            setTotalPage(data.total_page);
        } catch (err: any) {
            setError(err.response?.data?.message || "Lỗi khi tải dữ liệu.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchShops();
    }, [query, page, size]);

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        setPage(0);
        fetchShops();
    };

    const handleSelectAll = () => {
        if (selectedShops.length === shops.length) {
            setSelectedShops([]);
        } else {
            setSelectedShops(shops.map((shop) => shop.id));
        }
    };

    const [selectedShops, setSelectedShops] = useState<string[]>([]);

    const handleSelectShop = (id: string) => {
        if (selectedShops.includes(id)) {
            setSelectedShops(selectedShops.filter((shopId) => shopId !== id));
        } else {
            setSelectedShops([...selectedShops, id]);
        }
    };

    const handleDeleteSelected = async () => {
        if (selectedShops.length === 0) {
            alert("Vui lòng chọn ít nhất một sản phẩm để xóa.");
            return;
        }

        const confirmDelete = window.confirm("Bạn có chắc chắn muốn xóa các sản phẩm đã chọn?");
        if (!confirmDelete) return;

        try {
            await deleteShops(selectedShops);
            alert("Xóa Shop thành công.");
            setSelectedShops([]);
            fetchShops();
        } catch (err) {
            console.error("Error deleting Shops:", err);
            alert("Đã xảy ra lỗi khi xóa Shop.");
        }
    };


    if (loading) return <p>Đang tải dữ liệu...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Quản lý Shops</h1>
            <form onSubmit={handleSearch} className="mb-4 flex">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Tìm kiếm shop..."
                    className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                />
                <button
                    type="submit"
                    className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                    Tìm kiếm
                </button>
            </form>
            <div className="mb-4">
                <button
                    onClick={handleDeleteSelected}
                    className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                    disabled={selectedShops.length === 0}
                >
                    Xóa đã chọn
                </button>
            </div>
            <Link
                to="/shops/add"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
                Thêm Shop
            </Link>
            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border px-4 py-2">
                            <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                checked={selectedShops.length === shops.length && shops.length > 0}
                            />
                        </th>
                        <th className="border px-4 py-2 text-left">Hình ảnh</th>
                        <th className="border px-4 py-2 text-left">Tên Shop</th>
                        <th className="border px-4 py-2 text-left">Địa chỉ</th>
                        <th className="border px-4 py-2 text-left">Số điện thoại</th>
                        <th className="border px-4 py-2 text-left">Giờ mở cửa</th>
                        <th className="border px-4 py-2 text-left">Giờ đóng cửa</th>
                        <th className="border px-4 py-2 text-left">Trạng thái</th>
                    </tr>
                </thead>
                <tbody>
                    {shops.map((shop) => (
                        <tr key={shop.id}>
                               <td className="border px-4 py-2">
                                <input
                                    type="checkbox"
                                    checked={selectedShops.includes(shop.id)}
                                    onChange={() => handleSelectShop(shop.id)}
                                />
                            </td>
                            <td className="border px-4 py-2">
                                {shop.images?.link ? (
                                    <img
                                        src={shop.images.link}
                                        alt={shop.name}
                                        className="w-16 h-16 object-cover rounded-md"
                                    />
                                ) : (
                                    <span className="text-gray-500">Không có hình ảnh</span>
                                )}
                            </td>
                            <td className="border px-4 py-2">{shop.name}</td>
                            <td className="border px-4 py-2">{shop.address}</td>
                            <td className="border px-4 py-2">{shop.phone}</td>
                            <td className="border px-4 py-2">{shop.time_open}</td>
                            <td className="border px-4 py-2">{shop.time_close}</td>
                            <td className="border px-4 py-2">
                                {shop.is_active ? "Hoạt động" : "Ngừng hoạt động"}
                            </td>
                            <td className="border px-4 py-2">
                                <Link
                                    to={`/shops/edit/${shop.id}`}
                                    className="text-green-500 hover:underline mr-4"
                                >
                                    Chỉnh sửa
                                </Link>
                            </td>
                            <td className="border px-4 py-2">
                                <Link
                                    to={`/shops/${shop.id}`}
                                    className="text-blue-500 hover:underline"
                                >
                                    Xem chi tiết
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex justify-center items-center mt-4 space-x-2">
                {Array.from({ length: totalPage }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => setPage(index)}
                        className={`px-4 py-2 border rounded-lg ${page === index
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200 hover:bg-gray-300"
                            }`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Shops;
