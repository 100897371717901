import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { getShopById } from '../services/shop';

interface ShopType {
    id: string;
    name: string;
    address: string;
    phone: string;
    latitude: string;
    longitude: string;
    images: {
        object: string;
        id: string;
        bucket: string;
        name: string;
        type: string;
        uploaded_by: string;
        link: string;
    };
    created_time: string;
    is_active: boolean;
    type: { id: string; name: string }[];
    time_open: string;
    time_close: string;
}

const ShopDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [shop, setShop] = useState<ShopType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        const fetchShopData = async () => {
            try {
                setLoading(true);
                const data = await getShopById(id!);
                setShop(data);
            } catch (err: any) {
                setError(err.response?.data?.message || "Lỗi khi tải dữ liệu.");
            } finally {
                setLoading(false);
            }
        };
        fetchShopData();
    }, [id]);

    useEffect(() => {
        if (shop?.latitude && shop?.longitude) {
            const map = new maplibregl.Map({
                container: 'map-container',
                style: `https://tiles.goong.io/assets/goong_map_web.json?api_key=cRIhPxdMfmCOZMjcglQDuIePaqOfQbHyVs6Rxunc`,
                center: [parseFloat(shop.longitude), parseFloat(shop.latitude)],
                zoom: 15,
            });

            new maplibregl.Marker()
                .setLngLat([parseFloat(shop.longitude), parseFloat(shop.latitude)])
                .addTo(map);
        }
    }, [shop]);

    if (loading) {
        return <div className="text-center">Đang tải dữ liệu...</div>;
    }

    if (!shop) {
        return <div className="text-center">Không tìm thấy thông tin shop.</div>;
    }
    if (error) return <p>{error}</p>;
    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Chi Tiết Shop</h1>

            {/* Thông tin shop */}
            <div className="mb-4">
                <h2 className="text-xl font-semibold">Thông Tin</h2>
                <p><strong>Tên:</strong> {shop.name}</p>
                <p><strong>Địa chỉ:</strong> {shop.address}</p>
                <p><strong>Số điện thoại:</strong> {shop.phone}</p>
                <p><strong>Thời gian mở cửa:</strong> {shop.time_open}</p>
                <p><strong>Thời gian đóng cửa:</strong> {shop.time_close}</p>
                <p><strong>Trạng thái:</strong> {shop.is_active ? 'Hoạt động' : 'Ngừng hoạt động'}</p>
                <p><strong>Loại:</strong></p>
                <ul className="list-disc ml-4">
                    {shop.type.map((t) => (
                        <li key={t.id}>{t.name}</li>
                    ))}
                </ul>
            </div>

            {/* Hình ảnh */}
            <div className="mb-4">
                <h2 className="text-xl font-semibold">Hình Ảnh</h2>
                {shop.images ? (
                    <img
                        src={shop.images.link}
                        alt={shop.images.name || 'Hình ảnh'}
                        className="w-full h-auto rounded-lg shadow"
                    />
                ) : (
                    <p>Không có hình ảnh.</p>
                )}
            </div>


            {/* Vị trí */}
            <div className="mt-4">
                <h2 className="text-xl font-semibold">Vị Trí</h2>
                {shop.latitude && shop.longitude ? (
                    <div id="map-container" className="w-full h-96 rounded-lg shadow"></div>
                ) : (
                    <p>Không có thông tin vị trí.</p>
                )}
            </div>
        </div>
    );
};

export default ShopDetail;
