import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../components/Login";
import Dashboard from "../pages/Dashboard";
import Types from "../pages/Types";
import Shops from "../pages/Shops";
import Products from "../pages/Products";
import Accounts from "../pages/Accounts";
import ProtectedRoute from "../components/ProtectedRoute";
import ProductDetail from "../pages/ProductDetail";
import ProductAdd from "../pages/ProductAdd";
import ProductEdit from "../pages/ProductEdit";
import ShopAdd from "../pages/ShopAdd";
import ShopDetail from "../pages/ShopDetail";
import ShopEdit from "../pages/ShopEdit";
import AccountAdd from "../pages/AccountAdd";
import TypeAdd from "../pages/TypeAdd";
import TypeEdit from "../pages/TypeEdit";
import TypeDetail from "../pages/TypeDetail";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Route Đăng nhập */}
      <Route path="/login" element={<Login />} />

      {/* Layout chính: Dashboard */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        <Route index element={<h1>Chào mừng đến Dashboard!</h1>} />
        <Route path="types" element={<Types />} />
        <Route
          path="/products/:id"
          element={
            <ProtectedRoute>
              <ProductDetail />
            </ProtectedRoute>
          }
        />
        <Route path="shops" element={<Shops />} />
        <Route path="products" element={<Products />} />
        <Route path="accounts" element={<Accounts />} />
        <Route
          path="/products/add"
          element={
            <ProtectedRoute>
              <ProductAdd />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/products/edit/:id"
        element={
          <ProtectedRoute>
            <ProductEdit />
          </ProtectedRoute>
        }
      />

      <Route
        path="/shops/add"
        element={
          <ProtectedRoute>
            <ShopAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="/accounts/add"
        element={
          <ProtectedRoute>
            <AccountAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="/types/add"
        element={
          <ProtectedRoute>
            <TypeAdd />
          </ProtectedRoute>
        }
      />
       <Route
        path="/types/:id"
        element={
          <ProtectedRoute>
            <TypeDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/types/edit/:id"
        element={
          <ProtectedRoute>
            <TypeEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/shops/:id"
        element={
          <ProtectedRoute>
            <ShopDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/shops/edit/:id"
        element={
          <ProtectedRoute>
            <ShopEdit />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
