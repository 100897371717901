import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar: React.FC = () => {
  return (
    <div className="w-64 h-screen bg-blue-500 text-white flex flex-col">
      <div className="p-6 text-lg font-bold border-b border-blue-700">
        Admin Panel
      </div>
      <nav className="flex-1 p-4">
        <ul>
          <li className="mb-2">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `block p-2 rounded ${
                  isActive ? "bg-blue-700" : "hover:bg-blue-600"
                }`
              }
            >
              Dashboard
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/types"
              className={({ isActive }) =>
                `block p-2 rounded ${
                  isActive ? "bg-blue-700" : "hover:bg-blue-600"
                }`
              }
            >
              Quản lý Types
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/shops"
              className={({ isActive }) =>
                `block p-2 rounded ${
                  isActive ? "bg-blue-700" : "hover:bg-blue-600"
                }`
              }
            >
              Quản lý Shops
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/products"
              className={({ isActive }) =>
                `block p-2 rounded ${
                  isActive ? "bg-blue-700" : "hover:bg-blue-600"
                }`
              }
            >
              Quản lý Products
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/accounts"
              className={({ isActive }) =>
                `block p-2 rounded ${
                  isActive ? "bg-blue-700" : "hover:bg-blue-600"
                }`
              }
            >
              Quản lý Accounts
            </NavLink>
          </li>
          <li>
            <button
              onClick={() => {
                localStorage.removeItem("token");
                window.location.href = "/login";
              }}
              className="w-full text-left p-2 rounded hover:bg-blue-600"
            >
              Đăng xuất
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
