import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTypeById } from "../services/types";

interface TypeDetail {
    id: string;
    name: string;
    images: { link: string };
    is_active: boolean;
}

const TypeDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [type, setType] = useState<TypeDetail | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchType = async () => {
            try {
                setLoading(true);
                const data = await getTypeById(id!);
                setType(data);
            } catch (err: any) {
                setError(err.response?.data?.message || "Lỗi khi tải dữ liệu.");
            } finally {
                setLoading(false);
            }
        };

        fetchType();
    }, [id]);

    if (loading) return <p>Đang tải dữ liệu...</p>;
    if (error) return <p>{error}</p>;

    if (!type) return <p>Không tìm thấy.</p>;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Chi tiết</h1>
            <p>
                <strong>Tên:</strong> {type.name}
            </p>
            <p>
                <strong>Trạng thái:</strong> {type.is_active ? "Hoạt động" : "Ngừng"}
            </p>
            <div className="mt-4">
                <img
                    src={type.images?.link || ""}
                    alt={type.name}
                    className="w-64 h-64 object-cover"
                />
            </div>
        </div>
    );
};

export default TypeDetail;
