import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Siderbar";

const Dashboard: React.FC = () => {
  return (
    <div className="flex min-h-screen">
      {/* Sidebar luôn được hiển thị */}
      <Sidebar />
      <div className="flex-1 p-6 bg-gray-100">
        {/* Nội dung sẽ thay đổi theo route */}
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
