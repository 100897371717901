import React, { useEffect, useState } from "react";
import Sidebar from "../components/Siderbar";
import { getAllAccounts } from "../services/account";
import { Link } from "react-router-dom";


interface Account {
  id: string;
  fullname: string;
  phone: string;
  is_active: boolean;
  roles: string[];
  created_time: Date,
}


const Accounts: React.FC = () => {

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [query, setQuery] = useState<string>(""); // Tìm kiếm
  const [page, setPage] = useState<number>(0); // Trang hiện tại
  const [size, setSize] = useState<number>(10); // Số sản phẩm mỗi trang
  const [totalPage, setTotalPage] = useState<number>(1); // Tổng số trang


  const fetchAccounts = async () => {
    try {
      setLoading(true);
      const data = await getAllAccounts({ query, size, page });
      setAccounts(data.data);
      setTotalPage(data.total_page);
    } catch (err: any) {
      setError(err.response?.data?.message || "Lỗi khi tải dữ liệu.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [query, page, size]); // Gọi lại API khi query, page, hoặc size thay đổi

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setPage(0); // Reset về trang đầu tiên khi tìm kiếm
    fetchAccounts();
  };

  const handlePageClick = (newPage: number) => {
    setPage(newPage);
  };

  if (loading) return <p>Đang tải dữ liệu...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Quản lý Accounts SHIP</h1>
      {/* Tìm kiếm */}

      <Link
        to="/accounts/add"
        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
      >
        Thêm acc
      </Link>
      <form onSubmit={handleSearch} className="mb-4 flex">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Tìm kiếm sản phẩm..."
          className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
        />
        <button
          type="submit"
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Tìm kiếm
        </button>
      </form>
      {/* <div className="mb-4">
        <button
          onClick={handleDeleteSelected}
          className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
          disabled={selectedProducts.length === 0}
        >
          Xóa đã chọn
        </button>
      </div> */}
      {/* Bảng danh sách sản phẩm */}
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            {/* <th className="border px-4 py-2">
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedProducts.length === products.length && products.length > 0}
              />
            </th> */}
            <th className="border px-4 py-2 text-left">Tên</th>
            <th className="border px-4 py-2 text-left">ROLE</th>
            <th className="border px-4 py-2 text-left">SĐT</th>
            <th className="border px-4 py-2 text-left">Ngày tạo</th>
            <th className="border px-4 py-2 text-left">Trạng thái</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => (
            <tr key={account.id}>
              {/* <td className="border px-4 py-2">
                <input
                  type="checkbox"
                  checked={selectedProducts.includes(product.id)}
                  onChange={() => handleSelectProduct(product.id)}
                />
              </td> */}
              <td className="border px-4 py-2">{account.fullname}</td>
              {/* <td className="border px-4 py-2">
                <img
                  src={product.images[0]?.link || ""}
                  alt={product.name}
                  className="w-20 h-20 object-cover"
                />
              </td> */}
              <td className="border px-4 py-2">
                {account.roles.map((t) => t).join(", ")}
              </td>
              <td className="border px-4 py-2">{account.phone}</td>
              <td className="border px-4 py-2">
                {account.created_time ? new Date(account.created_time).toLocaleDateString() : 'N/A'}
              </td>

              <td className="border px-4 py-2">{account.is_active == true ? "Hoạt động" : "Dừng"}</td>

              {/* <td className="border px-4 py-2">
                <Link
                  to={`/products/edit/${product.id}`}
                  className="text-green-500 hover:underline mr-4"
                >
                  Chỉnh sửa
                </Link>
              </td> */}
              {/* <td className="border px-4 py-2">
                <Link
                  to={`/products/${product.id}`}
                  className="text-blue-500 hover:underline"
                >
                  Xem chi tiết
                </Link>
              </td> */}
            </tr>

          ))}
        </tbody>
      </table>
      {/* Thanh phân trang */}
      <div className="flex justify-center items-center mt-4 space-x-2">
        {Array.from({ length: totalPage }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageClick(index)}
            className={`px-4 py-2 border rounded-lg ${page === index
              ? "bg-blue-500 text-white"
              : "bg-gray-200 hover:bg-gray-300"
              }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Accounts;
