import axios from 'axios';
import maplibregl from 'maplibre-gl'; // Thêm MapLibre
import 'maplibre-gl/dist/maplibre-gl.css'; // Thêm CSS MapLibre
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import PlaceSearch from '../components/PlaceSearch';
import Sidebar from '../components/Siderbar';
import { getTypes, getUploadLink, uploadImage } from '../services/product';
import { createShop } from '../services/shop';

interface OptionType {
    value: string;
    label: string;
}

const ShopAdd: React.FC = () => {
    const navigate = useNavigate();

    const [name, setName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [phone, setPhone] = useState<string>('');
    const [timeOpen, setTimeOpen] = useState<string>('01:00');
    const [timeClose, setTimeClose] = useState<string>('00:00');
    const [typesSelected, setTypesSelected] = useState<OptionType[]>([]);
    const [image, setImage] = useState<File | null>(null);
    const [typesOptions, setTypesOptions] = useState<OptionType[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [map, setMap] = useState<maplibregl.Map | null>(null);
    const [placeSearchOptions, setPlaceSearchOptions] = useState<OptionType[]>([]);

    useEffect(() => {
        const fetchTypes = async () => {
            const typesData = await getTypes();
            setTypesOptions(typesData.map((type: any) => ({ value: type.id, label: type.name })));
        };

        fetchTypes();

        const container = document.getElementById('map-container'); // Sử dụng ID hợp lệ
        if (!container) {
            console.error("Container 'map-container' not found.");
            return;
        }

        // Lấy tọa độ hiện tại
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setLatitude(latitude);
                setLongitude(longitude);

                // Tạo bản đồ
                const mapInstance = new maplibregl.Map({
                    container,
                    style: `https://tiles.goong.io/assets/goong_map_web.json?api_key=cRIhPxdMfmCOZMjcglQDuIePaqOfQbHyVs6Rxunc`,
                    center: [longitude, latitude],
                    zoom: 13,
                });

                mapInstance.on('click', (e: any) => {
                    const { lng, lat } = e.lngLat;
                    setLatitude(lat);
                    setLongitude(lng);
                    fetchAddressFromCoordinates(lat, lng);
                });

                setMap(mapInstance);
            },
            (error) => {
                console.error('Error getting current location:', error);
                alert('Không thể lấy tọa độ hiện tại. Hãy bật quyền truy cập vị trí.');
            }
        );
    }, []);

    // Lấy địa chỉ từ tọa độ
    const fetchAddressFromCoordinates = async (lat: number, lng: number) => {
        try {
            const response = await axios.get('https://rsapi.goong.io/Geocode', {
                params: {
                    latlng: `${lat},${lng}`,
                    api_key: 'cyqYoiHZ8OhH1hz3RnEb43cNpgNHB9o7nH0GebFq',
                },
            });
            if (response.data.results && response.data.results.length > 0) {
                setAddress(response.data.results[0].formatted_address);
            }
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    // Xử lý tìm kiếm địa điểm
    const handlePlaceSearchInput = async (input: string) => {
        if (input.length < 3) return;
        try {
            const response = await axios.get('https://rsapi.goong.io/place/autocomplete', {
                params: {
                    input,
                    api_key: 'cyqYoiHZ8OhH1hz3RnEb43cNpgNHB9o7nH0GebFq',
                },
            });
            const options = response.data.predictions.map((pred: any) => ({
                value: pred.place_id.toString(), // Đảm bảo là chuỗi
                label: pred.description.toString(), // Đảm bảo là chuỗi
            }));

            setPlaceSearchOptions(options);
        } catch (error) {
            console.error('Error fetching place search options:', error);
        }
    };


    // const handleSelectChange = async (selected: OptionType | null) => {
    //     setSelectedPlace(selected);
    //     if (!selected) return;

    //     try {
    //         const response = await axios.get("https://rsapi.goong.io/place/detail", {
    //             params: {
    //                 api_key: "cyqYoiHZ8OhH1hz3RnEb43cNpgNHB9o7nH0GebFq", // Thay bằng API Key của bạn
    //                 place_id: selected.value,
    //             },
    //         });
    //         const result = response.data.result;
    //         onPlaceSelect({
    //             address: String(result.formatted_address), // Đảm bảo là chuỗi
    //             latitude: String(result.geometry.location.lat), // Đảm bảo là chuỗi
    //             longitude: String(result.geometry.location.lng), // Đảm bảo là chuỗi
    //         });
    //     } catch (error) {
    //         console.error("Error fetching place details:", error);
    //     }
    // };
    const handlePlaceSelect = async (selected: OptionType) => {
        try {
            const response = await axios.get('https://rsapi.goong.io/place/detail', {
                params: {
                    place_id: selected.value,
                    api_key: 'cyqYoiHZ8OhH1hz3RnEb43cNpgNHB9o7nH0GebFq',
                },
            });

            const result = response.data.result;
            setLatitude(result.geometry.location.lat);
            setLongitude(result.geometry.location.lng);
            setAddress(result.formatted_address);

            map?.flyTo({
                center: [result.geometry.location.lng, result.geometry.location.lat],
                zoom: 15,
            });
        } catch (error) {
            console.error('Error fetching place details:', error);
        }
    };

    const handleImageUpload = async (): Promise<string | null> => {
        if (!image) return null;

        try {
            const uploadLinkResponse = await getUploadLink(image.name, image.type);
            const { upload, object } = uploadLinkResponse;

            const uploadSuccess = await uploadImage(image, upload);
            if (uploadSuccess) {
                return object;
            }
        } catch (error) {
            console.error('Image upload failed:', error);
        }

        return null;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const uploadedImageObject = await handleImageUpload();
            if (!uploadedImageObject) {
                alert('Hình ảnh không thể tải lên');
                setIsSubmitting(false);
                return;
            }

            const shopData = {
                name,
                address,
                latitude: latitude?.toString() || '',
                longitude: longitude?.toString() || '',
                phone,
                time_open: timeOpen,
                time_close: timeClose,
                images: uploadedImageObject,
                type: typesSelected.map((type) => type.value),
            };

            console.log('Shop Data:', shopData);

            await createShop(shopData);
            alert('Thêm shop thành công');
            navigate('/shops');
        } catch (error) {
            console.error('Error creating shop:', error);
            alert('Đã xảy ra lỗi khi thêm shop');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex h-screen">
            <Sidebar />

            {/* Nội dung chính */}
            <div className="flex-1 p-6">
                <h1 className="text-2xl font-bold mb-4">Thêm Shop</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Tên Shop</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg"
                            required
                        />
                    </div>

                    {/* Kết hợp PlaceSearch và GoongMapPicker */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Tìm kiếm địa điểm</label>
                        <PlaceSearch
                            onPlaceSelect={({ address, latitude, longitude }) => {
                                setAddress(address);
                                setLatitude(latitude as any);
                                setLongitude(longitude as any);
                            }}
                        />
                    </div>

                    {/* <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2">Chọn trên bản đồ</label>
                
                <GoongMapPicker
                  apiKey="cRIhPxdMfmCOZMjcglQDuIePaqOfQbHyVs6Rxunc"
                  initialLatitude={Number(latitude)}
                  initialLongitude={Number(longitude)}
                  latitude={Number(latitude)} // Bổ sung để cập nhật động
                  longitude={Number(longitude)} // Bổ sung để cập nhật động
                  onLocationSelect={({ latitude, longitude } ) => {
                    setLatitude(latitude as any);
                    setLongitude(longitude as any);
                    setAddress(`Latitude: ${latitude}, Longitude: ${longitude}`);
                  }}
                />
              </div> */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Bản đồ</label>
                        <div id="map-container" style={{ height: '400px', width: '100%' }}></div>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Số điện thoại</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Thời gian mở cửa</label>
                        <input
                            type="time"
                            value={timeOpen}
                            onChange={(e) => setTimeOpen(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Thời gian đóng cửa</label>
                        <input
                            type="time"
                            value={timeClose}
                            onChange={(e) => setTimeClose(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Loại shop</label>
                        <Select
                            isMulti
                            options={typesOptions}
                            value={typesSelected}
                            onChange={(selected) => setTypesSelected(selected as OptionType[])}
                            className="w-full"
                            placeholder="Chọn loại shop"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-medium mb-2">Hình ảnh</label>
                        <input
                            type="file"
                            onChange={(e) => setImage(e.target.files?.[0] || null)}
                            className="w-full px-4 py-2 border rounded-lg"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className={`px-4 py-2 text-white rounded-lg ${isSubmitting ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
                            }`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Đang thêm..." : "Thêm Shop"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ShopAdd;
