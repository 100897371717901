import api from "./api";

interface TypeQueryParams {
    query?: string | null;
    size?: number;
    page?: number;
}
export const getAllTypes = async (params: TypeQueryParams) => {
    const response = await api.get("/types/", {
        params,
    });
    return response.data;
};


export const getTypeById = async (id: string) => {
    const response = await api.get(`/types/${id}`);
    return response.data;
};

export const createType = async (typeData: {
    name: string;
    images: string;
}) => {
    const response = await api.post("/types/", typeData);
    return response.data;
};

export const updateType = async (id: string, typeData: {
    name: string;
    images: string;
}) => {
    const response = await api.put(`/types/${id}`, typeData);
    return response.data;
};



// Xóa nhiều sản phẩm
export const deleteTypes = async (ids: string[]) => {
    const response = await api.put("/types/delete", { ids });
    return response.data;
};




