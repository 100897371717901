import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteTypes, getAllTypes } from "../services/types";


interface Type {
  id: string;
  name: string;
  images: { link: string };
  is_active: boolean;
}



const Types: React.FC = () => {


  const [types, setTypes] = useState<Type[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [query, setQuery] = useState<string>(""); // Tìm kiếm
  const [page, setPage] = useState<number>(0); // Trang hiện tại
  const [size, setSize] = useState<number>(10); // Số sản phẩm mỗi trang
  const [totalPage, setTotalPage] = useState<number>(1); // Tổng số trang

  const fetchTypes = async () => {
    try {
      setLoading(true);
      const data = await getAllTypes({ query, size, page });
      setTypes(data.data);
      setTotalPage(data.total_page);
    } catch (err: any) {
      setError(err.response?.data?.message || "Lỗi khi tải dữ liệu.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, [query, page, size]); // Gọi lại API khi query, page, hoặc size thay đổi

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setPage(0); // Reset về trang đầu tiên khi tìm kiếm
    fetchTypes();
  };

  const handlePageClick = (newPage: number) => {
    setPage(newPage);
  };


  const handleSelectType = (id: string) => {
    if (selectedTypes.includes(id)) {
      setSelectedTypes(selectedTypes.filter((typeId) => typeId !== id));
    } else {
      setSelectedTypes([...selectedTypes, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedTypes.length === types.length) {
      setSelectedTypes([]);
    } else {
      setSelectedTypes(types.map((type) => type.id));
    }
  };

  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const handleDeleteSelected = async () => {
    if (selectedTypes.length === 0) {
      alert("Vui lòng chọn ít nhất một sản phẩm để xóa.");
      return;
    }

    const confirmDelete = window.confirm("Bạn có chắc chắn muốn xóa các sản phẩm đã chọn?");
    if (!confirmDelete) return;

    try {
      await deleteTypes(selectedTypes);
      alert("Xóa thành công.");
      setSelectedTypes([]);
      fetchTypes();
    } catch (err) {
      console.error("Error deleting products:", err);
      alert("Đã xảy ra lỗi khi xóa sản phẩm.");
    }
  };


  if (loading) return <p>Đang tải dữ liệu...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Quản lý Types</h1>
      {/* Tìm kiếm */}

      <Link
        to="/types/add"
        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
      >
        Thêm
      </Link>
      <form onSubmit={handleSearch} className="mb-4 flex">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Tìm kiếm sản phẩm..."
          className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
        />
        <button
          type="submit"
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          Tìm kiếm
        </button>
      </form>
      <div className="mb-4">
        <button
          onClick={handleDeleteSelected}
          className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
          disabled={selectedTypes.length === 0}
        >
          Xóa đã chọn
        </button>
      </div>
      {/* Bảng danh sách sản phẩm */}
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            <th className="border px-4 py-2">
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedTypes.length === types.length && types.length > 0}
              />
            </th>
            <th className="border px-4 py-2 text-left">Tên</th>
            <th className="border px-4 py-2 text-left">Hình ảnh</th>
            <th className="border px-4 py-2 text-left">Trạng thái</th>
          </tr>
        </thead>
        <tbody>
          {types.map((type) => (
            <tr key={type.id}>
              <td className="border px-4 py-2">
                <input
                  type="checkbox"
                  checked={selectedTypes.includes(type.id)}
                  onChange={() => handleSelectType(type.id)}
                />
              </td>
              <td className="border px-4 py-2">{type.name}</td>
              <td className="border px-4 py-2">
                <img
                  src={type.images?.link || ""}
                  alt={type.name}
                  className="w-20 h-20 object-cover"
                />
              </td>
              <td className="border px-4 py-2">
                {type.is_active ? "Hoạt động" : "Ngừng"}
              </td>
              <td className="border px-4 py-2">
                <Link
                  to={`/types/edit/${type.id}`}
                  className="text-green-500 hover:underline mr-4"
                >
                  Chỉnh sửa
                </Link>
              </td>
              <td className="border px-4 py-2">
                <Link
                  to={`/types/${type.id}`}
                  className="text-blue-500 hover:underline"
                >
                  Xem chi tiết
                </Link>
              </td>
            </tr>

          ))}
        </tbody>
      </table>
      {/* Thanh phân trang */}
      <div className="flex justify-center items-center mt-4 space-x-2">
        {Array.from({ length: totalPage }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageClick(index)}
            className={`px-4 py-2 border rounded-lg ${page === index
              ? "bg-blue-500 text-white"
              : "bg-gray-200 hover:bg-gray-300"
              }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );


};

export default Types;
