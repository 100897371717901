import api from "./api";

interface ProductQueryParams {
  query?: string | null;
  size?: number;
  page?: number;
}

export const getAllProducts = async (params: ProductQueryParams) => {
  const response = await api.get("/products/", {
    params,
  });
  return response.data;
};



export const getProductById = async (id: string) => {
  const response = await api.get(`/products/${id}`);
  return response.data;
};



// Tạo sản phẩm mới
export const createProduct = async (productData: {
  name: string;
  images: string[];
  type: string[];
  price: number;
  description: string;
  shop: string;
}) => {
  const response = await api.post("/products/", productData);
  return response.data;
};

// Lấy danh sách loại sản phẩm
export const getTypes = async () => {
  const response = await api.get("/types/");
  return response.data.data;
};

// Lấy danh sách shops
export const getShops = async () => {
  const response = await api.get("/shops/");
  return response.data.data;
};

// Upload hình ảnh
export const uploadImage = async (file: File, uploadLink: string) => {
  const response = await fetch(uploadLink, {
    method: "PUT",
    body: file,
  });
  return response.ok;
};

// Lấy link upload
export const getUploadLink = async (fileName: string, fileType: string) => {
  const response = await api.get(`/files/upload-link/${fileName}?type=${encodeURIComponent(fileType)}`);
  return response.data;
};


// Cập nhật sản phẩm
export const updateProduct = async (id: string, productData: {
  name: string;
  images: string[];
  type: string[];
  price: number;
  description: string;
  shop: string;
}) => {
  const response = await api.put(`/products/${id}`, productData);
  return response.data;
};



// Xóa nhiều sản phẩm
export const deleteProducts = async (ids: string[]) => {
  const response = await api.put("/products/delete", { ids });
  return response.data;
};




