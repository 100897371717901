import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProductById, updateProduct, getTypes, getShops, getUploadLink, uploadImage } from "../services/product";
import Select from "react-select";

interface OptionType {
  value: string;
  label: string;
}

const ProductEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [typesSelected, setTypesSelected] = useState<OptionType[]>([]);
  const [shopSelected, setShopSelected] = useState<OptionType | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [typesOptions, setTypesOptions] = useState<OptionType[]>([]);
  const [shopsOptions, setShopsOptions] = useState<OptionType[]>([]);
  const [currentImages, setCurrentImages] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Lấy chi tiết sản phẩm
        const product = await getProductById(id!);
        setName(product.name);
        setPrice(product.price);
        setDescription(product.description);

        // Định dạng lại dữ liệu cho react-select
        setCurrentImages(product.images.map((img: any) => img.object));
        setTypesSelected(product.type.map((t: any) => ({ value: t.id, label: t.name })));

        // Nếu `product.shop` là object, định dạng lại nó
        if (product.shop && typeof product.shop === "string") {
          const shopsData = await getShops();
          const shop = shopsData.find((s: any) => s.id === product.shop);
          if (shop) {
            setShopSelected({ value: shop.id, label: shop.name });
          }
        }

        // Lấy danh sách loại sản phẩm và shop
        const typesData = await getTypes();
        const shopsData = await getShops();
        setTypesOptions(typesData.map((type: any) => ({ value: type.id, label: type.name })));
        setShopsOptions(shopsData.map((shop: any) => ({ value: shop.id, label: shop.name })));
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchData();
  }, [id]);


  const handleImageUpload = async (): Promise<string | null> => {
    if (!image) return null;

    try {
      const uploadLinkResponse = await getUploadLink(image.name, image.type);
      const { upload, object } = uploadLinkResponse;

      const uploadSuccess = await uploadImage(image, upload);
      if (uploadSuccess) {
        return object;
      }
    } catch (error) {
      console.error("Image upload failed:", error);
    }

    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      let uploadedImageObject = null;
      if (image) {
        uploadedImageObject = await handleImageUpload();
        if (!uploadedImageObject) {
          alert("Hình ảnh không thể tải lên");
          return;
        }
      }

      const productData = {
        name,
        images: uploadedImageObject ? [uploadedImageObject] : currentImages,
        type: typesSelected.map((type) => type.value),
        price,
        description,
        shop: shopSelected?.value || "",
      };

      await updateProduct(id!, productData);
      alert("Cập nhật sản phẩm thành công");
      navigate("/products");
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Đã xảy ra lỗi khi cập nhật sản phẩm");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Cập Nhật Sản Phẩm</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Tên sản phẩm</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Giá</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Mô tả</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Loại sản phẩm</label>
          <Select
            isMulti
            options={typesOptions}
            value={typesSelected}
            onChange={(selected) => setTypesSelected(selected as OptionType[])}
            className="w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Shop</label>
          <Select
            options={shopsOptions}
            value={shopSelected}
            onChange={(selected) => setShopSelected(selected as OptionType)}
            className="w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Hình ảnh</label>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files?.[0] || null)}
            className="w-full px-4 py-2 border rounded-lg"
          />
          {currentImages.length > 0 && (
            <p className="text-sm text-gray-500 mt-1">
              Hình ảnh hiện tại: {currentImages.join(", ")}
            </p>
          )}
        </div>
        <button
          type="submit"
          className={`px-4 py-2 text-white rounded-lg ${isSubmitting ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
            }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Đang cập nhật..." : "Cập Nhật"}
        </button>
      </form>
    </div>
  );
};

export default ProductEdit;
