import api from "./api";

// Hàm đăng nhập
export const login = async (phone: string, password: string) => {
  const response = await api.post("/auth/login-admin", { phone, password });
  localStorage.setItem("token", response.data.accessToken);
  return response.data;
};

// Hàm đăng xuất
export const logout = async () => {
  await api.post("/logout");
  // Xóa token khỏi localStorage
  localStorage.removeItem("token");
};

// Lấy thông tin người dùng
export const getUserInfo = async () => {
  const response = await api.get("/user");
  return response.data;
};
