import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUploadLink, uploadImage } from "../services/product";
import { getTypeById, updateType } from "../services/types";


const TypeEdit: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const [name, setName] = useState<string>("");
    const [image, setImage] = useState<File | null>(null);
    const [currentImages, setCurrentImages] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Lấy chi tiết sản phẩm
                const type = await getTypeById(id!);
                setName(type.name);
                setCurrentImages(type.images.object);
            } catch (error) {
                console.error("Error fetching product data:", error);
            }
        };

        fetchData();
    }, [id]);


    const handleImageUpload = async (): Promise<string | null> => {
        if (!image) return null;

        try {
            const uploadLinkResponse = await getUploadLink(image.name, image.type);
            const { upload, object } = uploadLinkResponse;

            const uploadSuccess = await uploadImage(image, upload);
            if (uploadSuccess) {
                return object;
            }
        } catch (error) {
            console.error("Image upload failed:", error);
        }

        return null;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            let uploadedImageObject = null;
            if (image) {
                uploadedImageObject = await handleImageUpload();
                if (!uploadedImageObject) {
                    alert("Hình ảnh không thể tải lên");
                    return;
                }
            }

            const typeData = {
                name,
                images: uploadedImageObject ? uploadedImageObject : currentImages,
            };

            await updateType(id!, typeData as any);
            alert("Cập nhật thành công");
            navigate("/types");
        } catch (error) {
            console.error("Error updating product:", error);
            alert("Đã xảy ra lỗi khi cập nhật");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Cập Nhật</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Tên sản phẩm</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Hình ảnh</label>
                    <input
                        type="file"
                        onChange={(e) => setImage(e.target.files?.[0] || null)}
                        className="w-full px-4 py-2 border rounded-lg"
                    />
                    {currentImages && (
                        <p className="text-sm text-gray-500 mt-1">
                            Hình ảnh hiện tại: {currentImages}
                        </p>
                    )}
                </div>
                <button
                    type="submit"
                    className={`px-4 py-2 text-white rounded-lg ${isSubmitting ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
                        }`}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Đang cập nhật..." : "Cập Nhật"}
                </button>
            </form>
        </div>
    );
};

export default TypeEdit;
