import axios from "axios";

// Lấy token từ localStorage hoặc bất kỳ nơi nào bạn lưu trữ
const getToken = (): string | null => {
    return localStorage.getItem("token");
};

// Tạo instance của Axios
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "http://localhost:4000", // URL gốc của API
    timeout: 10000, // Thời gian chờ request (ms)
});

// Thêm Interceptor cho Request để tự động thêm token vào headers
api.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers["token"] = `${token}`; // Thêm token vào headers
        }
        return config;
    },
    (error) => {
        // Xử lý lỗi khi cấu hình request
        return Promise.reject(error);
    }
);

// Xử lý lỗi Response nếu cần
api.interceptors.response.use(
    (response) => {
        return response; // Trả về response nếu thành công
    },
    (error) => {
        // Kiểm tra nếu lỗi là do hết hạn token hoặc lỗi 401
        if (error.response?.status === 401) {
            console.error("Token hết hạn hoặc không hợp lệ");
            // Redirect đến trang đăng nhập hoặc xử lý khác
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export default api;
