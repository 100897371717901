import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTypes, getUploadLink, uploadImage } from "../services/product";
import Select from "react-select";
import { getShopById, updateShop } from "../services/shop";
import PlaceSearch from "../components/PlaceSearch";

interface OptionType {
  value: string;
  label: string;
}

const ShopEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [timeOpen, setTimeOpen] = useState<string>("01:00");
  const [timeClose, setTimeClose] = useState<string>("00:00");
  const [typesSelected, setTypesSelected] = useState<OptionType[]>([]);
  const [image, setImage] = useState<File | null>(null);
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [typesOptions, setTypesOptions] = useState<OptionType[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Lấy chi tiết shop
        const shop = await getShopById(id!);
        setName(shop.name);
        setAddress(shop.address);
        setLatitude(shop.latitude);
        setLongitude(shop.longitude);
        setPhone(shop.phone);
        setTimeOpen(shop.time_open);
        setTimeClose(shop.time_close);
        setCurrentImage(shop.images?.object || null);

        // Định dạng dữ liệu loại shop
        setTypesSelected(shop.type.map((t: any) => ({ value: t.id, label: t.name })));

        // Lấy danh sách loại shop
        const typesData = await getTypes();
        setTypesOptions(typesData.map((type: any) => ({ value: type.id, label: type.name })));
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleImageUpload = async (): Promise<string | null> => {
    if (!image) return currentImage;

    try {
      const uploadLinkResponse = await getUploadLink(image.name, image.type);
      const { upload, object } = uploadLinkResponse;

      const uploadSuccess = await uploadImage(image, upload);
      if (uploadSuccess) {
        return object;
      }
    } catch (error) {
      console.error("Image upload failed:", error);
    }

    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const uploadedImageObject = await handleImageUpload();

      if (!uploadedImageObject) {
        alert("Hình ảnh không thể tải lên");
        setIsSubmitting(false);
        return;
      }

      const shopData = {
        name,
        address,
        latitude,
        longitude,
        phone,
        time_open: timeOpen,
        time_close: timeClose,
        images: uploadedImageObject,
        type: typesSelected.map((type) => type.value),
      };

      await updateShop(id!, shopData);
      alert("Cập nhật shop thành công");
      navigate("/shops");
    } catch (error) {
      console.error("Error updating shop:", error);
      alert("Đã xảy ra lỗi khi cập nhật shop");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Cập Nhật Shop</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Tên Shop</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Tìm kiếm địa điểm</label>
          <PlaceSearch
            onPlaceSelect={({ address, latitude, longitude }) => {
              setAddress(address);
              setLatitude(latitude as any);
              setLongitude(longitude as any);
            }}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Tọa độ</label>
          <div className="flex gap-4">
            <input
              type="text"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              className="w-1/2 px-4 py-2 border rounded-lg"
              placeholder="Latitude"
              required
            />
            <input
              type="text"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              className="w-1/2 px-4 py-2 border rounded-lg"
              placeholder="Longitude"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Số điện thoại</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Loại shop</label>
          <Select
            isMulti
            options={typesOptions}
            value={typesSelected}
            onChange={(selected) => setTypesSelected(selected as OptionType[])}
            className="w-full"
            placeholder="Chọn loại shop"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Hình ảnh</label>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files?.[0] || null)}
            className="w-full px-4 py-2 border rounded-lg"
          />
          {currentImage && (
            <p className="text-sm text-gray-500 mt-1">
              Hình ảnh hiện tại: {currentImage}
            </p>
          )}
        </div>
        <button
          type="submit"
          className={`px-4 py-2 text-white rounded-lg ${isSubmitting ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
            }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Đang cập nhật..." : "Cập Nhật"}
        </button>
      </form>
    </div>
  );
};

export default ShopEdit;
