import api from "./api";

// Lấy danh sách shops
export const getAllShops = async (params: { page: number; size: number; query?: string }) => {
  const { page, size, query } = params;
  const response = await api.get(`/shops/`, {
    params: { page, size, query },
  });
  return response.data;
};


// Thêm shop mới
export const createShop = async (shopData: {
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  phone: string;
  time_open: string;
  time_close: string;
  images: string;
  type: string[];
}) => {
  const response = await api.post("/shops", shopData);
  return response.data;
};


export const getShopById = async (id: string) => {
  const response = await api.get(`/shops/${id}`);
  return response.data;
};

export const updateShop = async (id: string, shopData: {
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  phone: string;
  time_open: string;
  time_close: string;
  images: string;
  type: string[];

}) => {
  const response = await api.put(`/shops/${id}`, shopData);
  return response.data;
};



export const deleteShops = async (ids: string[]) => {
  const response = await api.put("/shops/delete", { ids });
  return response.data;
};
